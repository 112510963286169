import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Card } from "fictional-waffle"

import Layout from "../components/layout"
import ProjectPreview from "../components/project-preview"
import SEO from "../components/seo"

const ProjectsPage = () => {
  const data = useStaticQuery(graphql`
    {
      allProjectsJson {
        edges {
          node {
            title
            subtitle
            url
            github
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const projects = data.allProjectsJson.edges

  // const cardLinksObj = {
  //   "Visit Live": "www.google.com",
  //   "View Source": "www.github.com",
  // }

  return (
    <>
      <SEO title="Projects" />
      <Layout>
        <h1 className="page-heading">Projects</h1>
        {projects.map(({ node: project }) => {
          const title = project.title
          const subtitle = project.subtitle
          const url = project.url
          const github = project.github
          const imageData = project.image.childImageSharp.fluid

          return (
            <ProjectPreview
              title={title}
              subtitle={subtitle}
              imageData={imageData}
              github={github}
              url={url}
            />
          )
        })}
      </Layout>
    </>
  )
}
export default ProjectsPage
